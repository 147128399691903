/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import { Link } from 'gatsby'
import { trackClickProduct } from '@chordcommerce/gatsby-theme-autonomy/src/redux/actions/analytics'
import { useDispatch } from 'react-redux'
import useProductMainImage from '~/hooks/components/use-product-main-image'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const SummaryImage = ({ slug, sku }) => {
  const path = sku.includes('|') ? 'bundles' : 'products'
  const url = `/${path}/${slug}/`
  const dispatch = useDispatch()
  const { image } = useProductMainImage({ slug, sku })
  if (!image) {
    return null
  }
  const gImage = getImage(image)

  return (
    <Box
      sx={{
        margin: ['10px', '25px'],
        minWidth: ['96px', '144px']
      }}
    >
      <Link to={url} onClick={() => dispatch(trackClickProduct(slug))}>
        <GatsbyImage image={gImage} alt={image.title} />
      </Link>
    </Box>
  )
}

export default SummaryImage
