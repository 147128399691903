/** @jsx jsx */
import { Link } from 'gatsby'
import { Box, Flex, Heading, Button, jsx } from 'theme-ui'
import { useAuth } from '~/context/auth-context'

const AccountPage = () => {
  const { user, isLoading, signOut } = useAuth()

  const handleLogout = () => {
    signOut()
  }

  return (
    <Box
      sx={{
        margin: ['0 auto 1.5rem auto', null, '42px auto 4.5rem auto'],
        maxWidth: '600px'
      }}
    >
      <Flex
        sx={{
          display: 'grid',
          gridGap: 4,
          marginTop: ['0', null, '42px'],
          textAlign: ['center', null, 'left'],
          padding: ['24px 16px', null, '42px 36px']
        }}
      >
        <Heading
          as="h1"
          sx={{
            lineHeight: '1em',
            textAlign: 'left'
          }}
        >
          {user.email}
        </Heading>
        <Button as={Link} to="/account/profile">
          Account
        </Button>
        <Button as={Link} to="/account/subscriptions">
          Subscriptions
        </Button>
        <Button onClick={() => handleLogout()}>Logout</Button>
      </Flex>
    </Box>
  )
}

export default AccountPage
