import React from 'react'
import { Router } from '@reach/router'
import AccountLoginPage from '~/components/Account/Login/Page'
import AccountPage from '~/components/Account/Page'
import AccountProfilePage from '~/components/Account/Profile/Page'
import AccountSubscriptionPage from '~/components/Account/Subscription/Page'
import AccountSubscriptionDetailPage from '~/components/Account/Subscription/Detail/Page'
import PrivateRoute from '~/components/Account/PrivateRoute'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'

const Account = () => {
  return (
    <Layout>
      <Metadata title="My account" />
      <Router basepath="/account">
        <AccountLoginPage path="/login" />
        <PrivateRoute path="/profile" component={AccountProfilePage} />
        <PrivateRoute path="/subscriptions" component={AccountSubscriptionPage} />
        <PrivateRoute path="/subscriptions/:subscriptionId" component={AccountSubscriptionDetailPage} />
        <PrivateRoute path="/" component={AccountPage} />
      </Router>
    </Layout>
  )
}

export default Account
