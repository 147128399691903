import axios from 'axios'

const API_URL = process.env.GATSBY_ACS_API_URL
const API_KEY = process.env.GATSBY_CHORD_API_KEY
export const KIT_PROPERTY_NAME = 'isKit'
export const CHORD_ROLE_AMBASSADOR_ID = 3
export const CHORD_ROLE_AMBASSADOR = 'ambassador'

class ChordService {
  async setAmbassadorOnOrder(orderNumber, ambassadorId) {
    const config = {
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        'Content-Type': 'application/json'
      }
    }
    return axios
      .patch(
        API_URL + `/api/orders/${orderNumber}`,
        {
          metadata: {
            ambassador_chord_id: ambassadorId
          }
        },
        config
      )
      .then()
      .catch(() => {})
  }

  async isProductKit(productId) {
    const config = {
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        'Content-Type': 'application/json'
      }
    }
    return axios
      .get(API_URL + `/api/products/${productId}/product_properties`, config)
      .then(response => {
        if (response.status === 200) {
          const isKitProp = response.data.product_properties.find(
            prodProp => prodProp.property_name === KIT_PROPERTY_NAME
          )
          return isKitProp !== undefined
        }
      })
      .catch(() => {})
  }

  allProducts() {
    //retrieve all the products
    const config = {
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        'Content-Type': 'application/json'
      }
    }

    return axios.get(API_URL + `/api/products`, config)
  }

  getAllStates(id) {
    //retrieve all the states
    const config = {
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        'Content-Type': 'application/json'
      }
    }

    return axios.get(API_URL + `/api/countries/${id}/states`, config)
  }
  async updateAmbassadorRole(userId) {
    const config = {
      headers: {
        Authorization: `Bearer ${API_KEY}`
      }
    }
    try {
      await axios.put(
        `${API_URL}/api/roles/${CHORD_ROLE_AMBASSADOR_ID}`,
        {
          name: CHORD_ROLE_AMBASSADOR,
          users: [{ id: userId }]
        },
        config
      )
    } catch (err) {}
  }
}

export default new ChordService()
