/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import { Container, Flex, Heading, jsx } from 'theme-ui'
import { useTranslate, useSubscriptions, useUser, useSubscription } from '@chordcommerce/gatsby-theme-autonomy'
import SubscriptionList from '~/components/Account/Subscription/List'
import { FaUndo, FaCaretDown, FaCaretUp } from 'react-icons/fa'
import Modal from 'react-modal'
import Dropdown from 'react-bootstrap/Dropdown'
import { useMediaQuery } from 'react-responsive'

const AccountSubscriptionPage = () => {
  /***SDK functions***/
  const translate = useTranslate()
  const subscriptions = useSubscriptions()
  const { subscription, skipSubscription } = useSubscription()
  const { user, loadUserSubscriptions } = useUser()

  /***Sections***/
  const [showSubscriptions, setShowSubscriptions] = useState(true)
  const [showMethods, setShowMethods] = useState(false)
  const [showOrders, setShowOrders] = useState(false)

  /***Modals***/
  const [showChangeCancelSubscription, setShowChangeCancelSubscription] = useState(false)
  const [showRemovePayment, setShowRemovePayment] = useState(false)
  const [showUpdatePayment, setShowUpdatePayment] = useState(false)
  const [selectedSubscription, setSelectedSubscription] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  /***Responsiveness***/
  const isXLScreen = useMediaQuery({ minWidth: 1200 })

  const modalStyles = {
    content: {
      top: '25%',
      left: '15%',
      right: '15%',
      bottom: 'auto',
      //transform: 'translate(-50%, -50%)',
      maxWidth: '80vw',
      maxHeight: '70vh',
      background: '#ECE9E0'
    }
  }

  useEffect(() => {
    //giving an error atm
    //loadUserSubscriptions()
  }, [])

  const skipNextShipment = async subscription => {
    try {
      await skipSubscription(subscription.id)
    } catch (err) {
      console.error(err)
    }
  }

  const ChangeCancelSubscription = () => {
    const { updateSubscription, cancelSubscription } = useSubscription(selectedSubscription.id)
    const onSubmit = async data => {
      setIsSubmitting(true)
      try {
        let response = await updateSubscription({
          shipAddressAttributes: data.addressAttributes,
          billAddressAttributes: data.addressAttributes,
          interval_units: 'month',
          interval_length: '2',
          end_date: '2021/12/31',
          actionable_date: '2030-08-18'
        })
        console.log(response)
      } catch (error) {
        console.error(error)
      }
      setIsSubmitting(false)
    }

    const cancelSelectedSubscription = () => {
      setIsSubmitting(true)
      try {
        cancelSubscription(selectedSubscription.id)
      } catch (error) {
        console.error(error)
      }
      setIsSubmitting(false)
    }

    return (
      <Modal
        isOpen={showChangeCancelSubscription}
        onRequestClose={() => setShowChangeCancelSubscription(false)}
        style={modalStyles}
      >
        <div className="mt-2 ps-3">
          <div className="OSR44">Anytime Gummies - Subscription</div>
          <div className="SFPRO20B">30 ct./10mg CBD</div>
          <div className="mt-3 p-4 pb-4 d-flex flex-column">
            <div className="OSR20">Current subscription saves you 20% over the one-time price.</div>
            <div className="row mt-3 d-flex flex-row align-items-center">
              <div className="col-5">
                <Dropdown className="mt-3">
                  <Dropdown.Toggle
                    className="blueFont text-uppercase px-4"
                    id="dropdown-product-types"
                    style={{ border: '1px solid #ECE9E0', borderRadius: '2px', backgroundColor: '#ECE9E0' }}
                  >
                    Every 1 Month
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="shopDropdownMenu">
                    <Dropdown.Item className="d-flex align-items-center justify-content-center shopDropdownMenuItem text-uppercase blueFont">
                      Every 1 Month
                    </Dropdown.Item>
                    <Dropdown.Item className="d-flex align-items-center justify-content-center shopDropdownMenuItem text-uppercase blueFont">
                      Every 2 Month
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-7 OSR18">You have (1) month left on your current subscription.</div>
            </div>
            <div className="row mt-4 d-flex flex-row align-items-center">
              <div className="col-5">
                <div
                  className="mediaBlockCTA"
                  style={{
                    borderRadius: '4px',
                    pointerEvents: isSubmitting ? 'none' : 'auto',
                    backgroundColor: isSubmitting && 'grey',
                    cursor: 'pointer'
                  }}
                  onClick={() => onSubmit()}
                >
                  UPDATE
                </div>
              </div>
              <div
                className="col-7 SFPRO20B blueFont"
                style={{
                  pointerEvents: isSubmitting ? 'none' : 'auto',
                  backgroundColor: isSubmitting && 'grey',
                  cursor: 'pointer'
                }}
                onClick={() => cancelSelectedSubscription()}
              >
                Cancel subscription
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  const RemovePaymentCard = () => (
    <Modal isOpen={showRemovePayment} onRequestClose={() => setShowRemovePayment(false)} style={modalStyles}>
      <div className="mt-2 ps-3">
        <div className="OSR44">Remove Payment Card</div>
        <div className="d-flex flex-column mt-5">
          <div className="OSR18">Are you sure you want to remove?</div>
          <div className="d-flex flex-row align-items-center mt-3">
            <img src="..." />
            <div className="OSR18">**** **** **** 1303</div>
          </div>
        </div>
        <div className="row mt-5 d-flex flex-row align-items-center">
          <div className="col-5">
            <div
              className="mediaBlockCTA"
              style={{
                borderRadius: '4px',
                pointerEvents: isSubmitting ? 'none' : 'auto',
                backgroundColor: isSubmitting && 'grey',
                cursor: 'pointer'
              }}
            >
              CONFIRM
            </div>
          </div>
          <div
            className="col-7 SFPRO20B blueFont"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowRemovePayment(false)}
          >
            Cancel
          </div>
        </div>
      </div>
    </Modal>
  )

  const UpdatePaymentCard = () => (
    <Modal isOpen={showUpdatePayment} onRequestClose={() => setShowUpdatePayment(false)} style={modalStyles}>
      <div className="mt-2 ps-xl-3 ps-1">
        <div className="OSR44">Update Payment Card</div>
        <div className="row mt-3 d-flex flex-column flex-xl-row">
          <div className="col-2"></div>
          <div className="col-12 col-md-7 ">
            <div className="d-flex flex-column SFPRO18R">
              <label htmlFor="name" className="form-label SFPRO16R">
                Name on Card
              </label>
              <input name="name" type="text" style={{ background: '#ECE9E0' }} />
            </div>
          </div>
        </div>

        <div className="row d-flex flex-column flex-xl-row mt-3 mt-xl-0">
          <div className="col-6 col-md-3 col-xl-2 d-flex flex-column justify-content-end">
            <img src="..." />
          </div>
          <div className="col-12 col-md-7">
            <div className="row mt-3 d-flex flex-column flex-xl-row">
              <div className="col-10 col-xl-7 d-flex flex-column SFPRO18R">
                <label htmlFor="cnumber" className="form-label SFPRO16R">
                  Card Number
                </label>
                <input name="cnumber" type="text" style={{ background: '#ECE9E0' }} />
              </div>
              <div className="col-7 col-sm-5 col-xl-3 d-flex flex-column SFPRO18R">
                <label htmlFor="expiration" className="form-label SFPRO16R">
                  Expiration
                </label>
                <input name="expiration" type="text" style={{ background: '#ECE9E0' }} />
              </div>
              <div className="col-5 col-sm-4 col-xl-2 d-flex flex-column SFPRO18R">
                <label htmlFor="cvv" className="form-label SFPRO16R">
                  CVV
                </label>
                <input name="cvv" type="text" style={{ background: '#ECE9E0' }} />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div className="mediaBlockCTA" style={{ borderRadius: '4px', width: isXLScreen ? '30%' : '50%' }}>
            UPDATE
          </div>
        </div>
      </div>
    </Modal>
  )

  return (
    <Container>
      <Flex
        sx={{
          flexDirection: 'column',
          marginBottom: ['1.5rem', null, '4.5rem'],
          marginTop: ['0', null, '42px'],
          textAlign: ['center', null, 'left'],
          padding: ['24px 16px', null, '42px 36px']
        }}
      >
        <Heading
          as="h1"
          sx={{
            lineHeight: '1em',
            textAlign: 'left',
            paddingBottom: '1rem',
            marginBottom: '1rem'
          }}
        >
          {translate('subscriptions.page_title')}
        </Heading>
        {/*<SubscriptionList subscriptions={subscriptions} />*/}

        <div className="row mt-4 d-flex flex-column flex-xl-row">
          <div className="col-12 col-xl-6 pe-xl-4">
            <div className="d-flex flex-row border-bottom align-items-center justify-content-between">
              <div className="OSR44">Your Subscriptions</div>
              <div className="d-block d-xl-none" onClick={() => setShowSubscriptions(!showSubscriptions)}>
                {showSubscriptions ? <FaCaretUp /> : <FaCaretDown />}
              </div>
            </div>
            {(showSubscriptions || isXLScreen) && (
              <>
                <div className="row mt-2 pb-2 d-flex flex-column flex-sm-row">
                  <div className="col-12 col-sm-2 mt-3">
                    <img className="img-fluid" src="..." />
                  </div>
                  <div className="col-12 col-sm-7 mt-3 ps-1 d-flex flex-column justify-content-between">
                    <div>
                      <div className="SFPRO18B">CBD Salve - Mint & Rosemary</div>
                      <div className="SFPRO16R mt-1">200 mg</div>
                      <div className="SFPRO16R mt-1">
                        <span className="fw-bolder">SUBSCRIPTION:</span> renews in 1 month <FaUndo />
                        <span>Next shipment: 20.02.2022</span>
                        <div
                          className="SFPRO18R blueFont text-decoration-underline"
                          style={{ cursor: 'pointer' }}
                          //onClick={() => skipNextShipment(subscription)}
                        >
                          Skip next shipment
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <div
                        className="SFPRO18R blueFont text-decoration-underline"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          /*setSelectedSubscription(subscription);*/ setShowChangeCancelSubscription(true)
                        }}
                      >
                        change/cancel
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-3 mt-3 SFPRO20R">$40/month</div>
                </div>

                {/* {user?.subscriptions?.map(subscription) => (      
                    <div>        
                      <div>{subscription.id}</div>        
                      <div>{subscription.interval}</div>      
                    </div>    
                    )}
                */}
              </>
            )}
          </div>
          <div className="col-12 col-xl-6 mt-3 mt-xl-0 ps-xl-4">
            <div className="d-flex flex-row border-bottom align-items-center justify-content-between">
              <div className="OSR44">Payment Methods</div>
              <div className="d-block d-xl-none" onClick={() => setShowMethods(!showMethods)}>
                {showMethods ? <FaCaretUp /> : <FaCaretDown />}
              </div>
            </div>
            {(showMethods || isXLScreen) && (
              <div className="row mt-2 pb-2">
                <div className="col mt-3">visa</div>
                <div className="col mt-3 SFPRO18B">****1303</div>
                <div className="col mt-3 SFPRO18R">exp: 03/26</div>
                <div
                  className="col mt-3 SFPRO18R blueFont text-decoration-underline"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowUpdatePayment(true)}
                >
                  change
                </div>
                <div
                  className="col mt-3 SFPRO18R blueFont text-decoration-underline"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowRemovePayment(true)}
                >
                  remove
                </div>
                <div className="col mt-3 SFPRO18B">default</div>
              </div>
            )}
          </div>
        </div>

        <div className="row mt-4 d-flex flex-column flex-xl-row">
          <div className="col-12 col-xl-6 pe-xl-4">
            <div className="d-flex flex-row border-bottom align-items-center justify-content-between">
              <div className="OSR44">Your Orders</div>
              <div className="d-block d-xl-none" onClick={() => setShowOrders(!showOrders)}>
                {showOrders ? <FaCaretUp /> : <FaCaretDown />}
              </div>
            </div>
          </div>
        </div>
      </Flex>
      <ChangeCancelSubscription />
      <RemovePaymentCard />
      <UpdatePaymentCard />
    </Container>
  )
}

export default AccountSubscriptionPage
