/** @jsx jsx */
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Spinner,
  Text,
  jsx,
  Grid,
  Divider
} from 'theme-ui'
import {
  useTranslate,
  useSubscription
} from '@chordcommerce/gatsby-theme-autonomy'
//import { loadStripe } from '@stripe/stripe-js'
//import { Elements } from '@stripe/react-stripe-js'
import SummaryImage from '~/components/Account/Subscription/SummaryImage'
import ShippingCard from '~/components/Account/Subscription/Detail/ShippingCard'
import BillingCard from '~/components/Account/Subscription/Detail/BillingCard'
import { Fragment } from 'react'
import SubscriptionDetailsPauseForm from '~/components/Account/Subscription/Detail/PauseForm'

const AccountSubscriptionDetailPage = ({ subscriptionId }) => {
  const translate = useTranslate()
  const {
    isLoaded,
    isFetching,
    subscription,
    skipSubscription,
    cancelSubscription,
    resumeSubscription,
    pauseSubscription
  } = useSubscription(subscriptionId)

  if (!isLoaded) return null

  return (
    <Container>
      <Flex
        sx={{
          width: '100%',
          flexDirection: 'column',
          marginBottom: ['1.5rem', null, '4.5rem'],
          textAlign: ['center', null, 'center'],
          padding: ['24px 16px', null, '42px 36px']
        }}
      >
        <Heading
          as="h1"
          sx={{
            lineHeight: '1em',
            textAlign: 'left',
            paddingBottom: '1rem',
            marginBottom: '1rem'
          }}
        >
          {translate('subscriptions.details')}
        </Heading>

        {subscription.state !== 'canceled' && (
          <SubscriptionActions
            isFetching={isFetching}
            skipSubscription={skipSubscription}
            cancelSubscription={cancelSubscription}
            isPaused={subscription.state === 'paused'}
            resumeSubscription={resumeSubscription}
            pauseSubscription={pauseSubscription}
          />
        )}

        <Divider />

        <Addresses subscription={subscription} />

        <Divider />

        <LineItems subscription={subscription} />
      </Flex>
    </Container>
  )
}

const SubscriptionActions = ({
  isFetching,
  skipSubscription,
  cancelSubscription,
  isPaused,
  resumeSubscription,
  pauseSubscription
}) => {
  const translate = useTranslate()

  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
        justifyContent: ['space-between', 'flex-end'],
        marginBottom: '1rem'
      }}
    >
      {isFetching && <Spinner color="inherit" />}

      {!isFetching && (
        <Fragment>
          {isPaused ? (
            <SubscriptionDetailsPauseForm
              action={date => resumeSubscription(date)}
              actionType={'resume'}
            />
          ) : (
            <Fragment>
              <SubscriptionDetailsPauseForm
                action={date => pauseSubscription(date)}
                actionType={'pause'}
              />
              <Button m="0.5rem" onClick={skipSubscription}>
                {translate('subscriptions.skip')}
              </Button>
            </Fragment>
          )}
          <Button m="0.5rem" onClick={cancelSubscription}>
            {translate('subscriptions.cancel')}
          </Button>
        </Fragment>
      )}
    </Flex>
  )
}

//const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

const Addresses = ({ subscription }) => {
  const translate = useTranslate()

  /*return (
    <Elements stripe={stripePromise}>
      <Flex
        sx={{
          flexDirection: ['column', 'column', 'row'],
          justifyContent: 'space-around',
          alignItems: 'flex-start'
        }}
      >
        <ShippingCard
          title={translate('subscriptions.shipping')}
          subscription={subscription}
        />
        <BillingCard
          title={translate('subscriptions.billing')}
          subscription={subscription}
        />
      </Flex>
    </Elements>
  )*/
  return null
}

const LineItems = ({ subscription }) => {
  const translate = useTranslate()
  const {
    state,
    lineItems,
    intervalLength,
    intervalUnits,
    actionableDate
  } = subscription

  return (
    <Box
      sx={{
        marginTop: ['20px', '45px'],
        marginBottom: ['20px', '45px'],
        flexDirection: ['column', 'row'],
        justifyContent: 'space-around'
      }}
    >
      <Heading>{translate('subscriptions.products')}</Heading>
      <Flex
        sx={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Text mr="1rem">
          {translate('subscriptions.status', {
            status: state
          })}
        </Text>
        <Text mr="1rem">|</Text>
        <Text mr="1rem">
          {translate('subscriptions.auto_renew_interval', {
            length: intervalLength,
            unit: intervalUnits
          })}
        </Text>
        {actionableDate && (
          <Fragment>
            <Text mr="1rem">|</Text>
            <Text mr="1rem">
              {translate('subscriptions.action_date', {
                action_date: actionableDate
              })}
            </Text>
          </Fragment>
        )}
      </Flex>
      {lineItems && (
        <Grid columns={[1, 2, 3]} gap={'3rem'}>
          {lineItems
            .sort((a, b) => a.id - b.id)
            .map((lineItem, index) => (
              <Box key={index}>
                <SummaryImage slug={lineItem.productSlug} sku={lineItem.sku} />
                <Box
                  sx={{
                    justifyContent: 'center',
                    textAlign: 'center',
                    maxWidth: '195px'
                  }}
                >
                  {lineItem.sku}
                </Box>
              </Box>
            ))}
        </Grid>
      )}
    </Box>
  )
}

export default AccountSubscriptionDetailPage
