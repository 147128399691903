/** @jsx jsx */
import { useState, useEffect } from 'react'
import { Label, Flex, Button, Input, Text, Spinner, jsx } from 'theme-ui'
import { useForm } from 'react-hook-form'
import { useTranslate, useUser } from '@chordcommerce/gatsby-theme-autonomy'
import ChordService from '~/api/chord-services'
import Dropdown from 'react-bootstrap/Dropdown'

const AccountProfileForm = () => {
  const translate = useTranslate()
  const { user, modifyUser, loadUser } = useUser()
  const [states, setStates] = useState([])
  const [selectedBillingState, setSelectedBillingState] = useState(null)
  const [selectedShippingState, setSelectedShippingState] = useState(null)
  const CountryID = 233

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    mode: 'onBlur'
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showUpdated, setShowUpdated] = useState(false)

  useEffect(() => {
    loadUser()
    function fetchStates() {
      ChordService.getAllStates(CountryID)
        .then(states => setStates(states.data?.states && states.data.states))
        .catch(err => {
          console.error('error fetching states', err)
        })
    }

    fetchStates()
    return () => {}
  }, [])

  useEffect(() => {
    if (user?.data?.id) {
      setValue('email', user?.data?.email && user?.data?.email)
      setValue('fullName', user?.data?.name && user?.data?.name)
      setValue('billingFullName', user?.data?.billAddress?.name && user?.data?.billAddress?.name)
      setValue('billingAddress1', user?.data?.billAddress?.address1 && user?.data?.billAddress?.address1)
      setValue('billingAddress2', user?.data?.billAddress?.address2 && user?.data?.billAddress?.address2)
      setValue('billingCity', user?.data?.billAddress?.city && user?.data?.billAddress?.city)
      setValue('billingState', user?.data?.billAddress?.state?.name && user?.data?.billAddress?.state?.name)
      setValue('billingZipcode', user?.data?.billAddress?.zipcode && user?.data?.billAddress?.zipcode)
      setValue('billingPhone', user?.data?.billAddress?.phone && user?.data?.billAddress?.phone.replaceAll('-', ''))
      setValue('shippingFullName', user?.data?.shipAddress?.name && user?.data?.shipAddress?.name)
      setValue('shippingAddress1', user?.data?.shipAddress?.address1 && user?.data?.shipAddress?.address1)
      setValue('shippingAddress2', user?.data?.shipAddress?.address2 && user?.data?.shipAddress?.address2)
      setValue('shippingCity', user?.data?.shipAddress?.city && user?.data?.shipAddress?.city)
      setValue('shippingState', user?.data?.shipAddress?.state?.name && user?.data?.shipAddress?.state?.name)
      setValue('shippingZipcode', user?.data?.shipAddress?.zipcode && user?.data?.shipAddress?.zipcode)
      setValue('shippingPhone', user?.data?.shipAddress?.phone && user?.data?.shipAddress?.phone.replaceAll('-', ''))
    }
  }, [user])

  const findStateId = stateName => {
    return states && stateName && states.find(state => state.name === stateName)?.id
  }

  const onSubmit = async data => {
    setLoading(true)
    setError(null)
    try {
      await modifyUser({
        billAddressAttributes: {
          name: data?.billingFullName && data?.billingFullName,
          address1: data?.billingAddress1 && data?.billingAddress1,
          address2: data?.billingAddress2 && data?.billingAddress2,
          city: data?.billingCity && data?.billingCity,
          country_id: CountryID,
          state_id:
            (selectedBillingState && findStateId(selectedBillingState.name)) ||
            findStateId(data?.billingState && data?.billingState),
          zipcode: data?.billingZipcode && data?.billingZipcode,
          phone: data?.billingPhone && data?.billingPhone
        },
        shipAddressAttributes: {
          name: data?.shippingFullName && data?.shippingFullName,
          address1: data?.shippingAddress1 && data?.shippingAddress1,
          address2: data?.shippingAddress2 && data?.shippingAddress2,
          city: data?.shippingCity && data?.shippingCity,
          country_id: CountryID,
          state_id:
            (selectedShippingState && findStateId(selectedShippingState.name)) ||
            findStateId(data?.ShippingState && data?.ShippingState),
          zipcode: data?.shippingZipcode && data?.shippingZipcode,
          phone: data?.shippingPhone && data?.shippingPhone
        }
      })
    } catch (error) {
      setError(error)
    }

    setLoading(false)
    setShowUpdated(true)

    //show "Profile updated" for 3 seconds
    const timer = setTimeout(() => {
      setShowUpdated(false)
    }, 3000)

    return () => clearTimeout(timer)
  }

  const SelectState = ({ selectedState, handleSelected, name, billing }) => {
    return (
      <Dropdown className="mt-1 d-flex flex-row align-items-start">
        <Dropdown.Toggle
          className="darkFont text-uppercase px-4"
          id={`dropdown-${name}`}
          style={{ border: '1px solid #ECE9E0', borderRadius: '2px', backgroundColor: 'white' }}
          {...register(name, { required: true })}
        >
          {selectedState?.name ||
            (billing ? user?.data?.billAddress?.state?.name : user?.data?.shipAddress?.state?.name) ||
            (states?.length > 0 && states[0].name)}
        </Dropdown.Toggle>

        <Dropdown.Menu className="shopDropdownMenu" style={{ maxHeight: '120px', overflowY: 'scroll' }}>
          {states &&
            states.length > 0 &&
            states.map((state, index) => (
              <Dropdown.Item
                key={index}
                className="d-flex align-items-center justify-content-center shopDropdownMenuItem text-uppercase darkFont"
                onClick={() => handleSelected(state)}
              >
                {state.name}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <Flex as="form" onSubmit={handleSubmit(onSubmit)} sx={{ flexDirection: 'column' }}>
      <Text
        sx={{
          fontSize: 4,
          fontWeight: 'bold'
        }}
      >
        Account Info
      </Text>

      <Label htmlFor="email">{translate('profile.email_input_label')}</Label>
      <Input {...register('email')} type="text" mb="2" />

      <Label htmlFor="fullName">{translate('profile.name_input_label')}</Label>
      <Input {...register('fullName', { required: true, maxLength: 80 })} type="text" mb="2" />
      {errors?.fullName?.type === 'required' && 'Full name is required'}

      <Text
        sx={{
          fontSize: 4,
          fontWeight: 'bold'
        }}
        mt="3"
      >
        Billing Info
      </Text>

      {/*<Label htmlFor="billingFirstName">{translate('profile.first_name_input_label')}</Label>
      <Input {...register('billingFirstName')} type="text" mb="2" />

      <Label htmlFor="billingLastName">{translate('profile.last_name_input_label')}</Label>
      <Input {...register('billingLastName')} type="text" mb="2" />*/}

      <Label htmlFor="billingFullName">{translate('profile.name_input_label')}</Label>
      <Input {...register('billingFullName', { required: true, maxLength: 80 })} type="text" mb="2" />
      {errors?.billingFullName?.type === 'required' && <Text variant="formError">Billing name required</Text>}

      <Label htmlFor="billingAddress1">{translate('profile.address1_input_label')}</Label>
      <Input {...register('billingAddress1', { required: true })} type="text" mb="2" />
      {errors?.billingAddress1?.type === 'required' && <Text variant="formError">Billing address required</Text>}

      <Label htmlFor="billingAddress2">{translate('profile.address2_input_label')}</Label>
      <Input {...register('billingAddress2')} type="text" mb="2" />

      <Label htmlFor="billingCity">{translate('profile.city_input_label')}</Label>
      <Input {...register('billingCity', { required: true })} type="text" mb="2" />
      {errors?.billingCity?.type === 'required' && <Text variant="formError">Billing city required</Text>}

      {/*<Label htmlFor="billingState">{translate('profile.state_input_label')}</Label>
      <Input {...register('billingState')} type="text" mb="2" />*/}

      <Label htmlFor="billingState">{translate('profile.state_input_label')}</Label>
      <SelectState
        selectedState={selectedBillingState}
        handleSelected={setSelectedBillingState}
        name="billingState"
        billing={true}
      />
      {errors?.billingState?.type === 'required' && <Text variant="formError">Billing state required</Text>}

      <Label className="mt-3" htmlFor="billingZipcode">
        {translate('profile.zip_input_label')}
      </Label>
      <Input {...register('billingZipcode', { required: true, minlength: 5, maxLength: 5 })} type="text" mb="2" />
      {errors?.billingZipcode?.type === 'required' && <Text variant="formError">Billing zipcode required</Text> &&
        errors?.billingZipcode?.type === 'maxLength' && <Text variant="formError">Zipcode maximum length is 5</Text> &&
        errors?.billingZipcode?.type === 'minLength' && <Text variant="formError">Zipcode minimum length is 5</Text>}

      {/*<Label htmlFor="billingCountry">{translate('profile.country_input_label')}</Label>
      <Input {...register('billingCountry')} type="text" mb="2" />*/}

      <Label htmlFor="billingPhone">{translate('profile.phone_input_label')}</Label>
      <Input {...register('billingPhone', { required: true, maxLength: 10 })} type="text" mb="2" />
      {errors?.billingPhone?.type === 'required' && <Text variant="formError">Billing phone required</Text> &&
        errors?.billingPhone?.type === 'maxLength' && (
          <Text variant="formError">Phone number maximum length is 10</Text>
        )}

      <Text
        sx={{
          fontSize: 4,
          fontWeight: 'bold'
        }}
        mt="3"
      >
        Shipping Info
      </Text>

      {/*<Label htmlFor="shippingFirstName">{translate('profile.first_name_input_label')}</Label>
      <Input {...register('shippingFirstName')} type="text" mb="2" />

      <Label htmlFor="shippingLastName">{translate('profile.last_name_input_label')}</Label>
      <Input {...register('shippingLastName')} type="text" mb="2" />*/}

      <Label htmlFor="shippingFullName">{translate('profile.name_input_label')}</Label>
      <Input {...register('shippingFullName', { required: true, maxLength: 80 })} type="text" mb="2" />
      {errors?.shippingFullName?.type === 'required' && <Text variant="formError">Shipping name required</Text>}

      <Label htmlFor="shippingAddress1">{translate('profile.address1_input_label')}</Label>
      <Input {...register('shippingAddress1', { required: true })} type="text" mb="2" />
      {errors?.shippingAddress1?.type === 'required' && <Text variant="formError">Shipping address required</Text>}

      <Label htmlFor="shippingAddress2">{translate('profile.address2_input_label')}</Label>
      <Input {...register('shippingAddress2')} type="text" mb="2" />

      <Label htmlFor="shippingCity">{translate('profile.city_input_label')}</Label>
      <Input {...register('shippingCity', { required: true })} type="text" mb="2" />
      {errors?.shippingCity?.type === 'required' && <Text variant="formError">Shipping City required</Text>}

      {/*<Label htmlFor="shippingState">{translate('profile.state_input_label')}</Label>
      <Input {...register('shippingState')} type="text" mb="2" />*/}

      <Label htmlFor="shippingState">{translate('profile.state_input_label')}</Label>
      <SelectState
        selectedState={selectedShippingState}
        handleSelected={setSelectedShippingState}
        name="shippingState"
        billing={false}
      />
      {errors?.shippingState?.type === 'required' && <Text variant="formError">Shipping State required</Text>}

      <Label className="mt-3" htmlFor="shippingZipcode">
        {translate('profile.zip_input_label')}
      </Label>
      <Input {...register('shippingZipcode', { required: true, minlength: 5, maxLength: 5 })} type="text" mb="2" />
      {errors?.shippingZipcode?.type === 'required' && <Text variant="formError">Shipping zipcode required</Text> &&
        errors?.shippingZipcode?.type === 'maxLength' && <Text variant="formError">Zipcode maximum length is 5</Text> &&
        errors?.shippingZipcode?.type === 'minLength' && <Text variant="formError">Zipcode minimum length is 5</Text>}

      {/*<Label htmlFor="shippingCountry">{translate('profile.country_input_label')}</Label>
      <Input {...register('shippingCountry')} type="text" mb="2" />*/}

      <Label htmlFor="shippingPhone">{translate('profile.phone_input_label')}</Label>
      <Input {...register('shippingPhone', { required: true, maxLength: 10 })} type="text" mb="2" />
      {errors?.shippingPhone?.type === 'required' && <Text variant="formError">Shipping phone required</Text> &&
        errors?.shippingPhone?.type === 'maxLength' && (
          <Text variant="formError">Phone number maximum length is 10</Text>
        )}

      {error && <Text variant="formError">{error.message}</Text>}

      {showUpdated && <Text>Profile updated!</Text>}

      {loading ? (
        <Spinner data-testid="spinner" size="15" color="inherit" />
      ) : (
        <Button type="submit" mb="4" mt="3">
          {!loading && translate('profile.submit_button')}
        </Button>
      )}
    </Flex>
  )
}

export default AccountProfileForm
