/** @jsx jsx */
import { Flex, jsx, Button, Input } from 'theme-ui'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'
import { useAuth, useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

const AuthLoginForm = () => {
  const translate = useTranslate()
  const { register, handleSubmit } = useForm()
  const { login } = useAuth()
  const [error, setError] = useState(null)

  const onLogin = async ({ email }) => {
    setError(null)
    try {
      await login({ email, showUI: true, redirectURI: window.location.origin })
      navigate('/account')
    } catch (error) {
      setError(error)
    }
  }
  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(onLogin)}
      sx={{ flexDirection: 'column' }}
    >
      <Input {...register('email')} mb="2" />
      <Button type="submit" mb="4">
        {translate('login.sign_in')}
      </Button>
      {error && <p className="error">{error.message}</p>}
    </Flex>
  )
}
export default AuthLoginForm
