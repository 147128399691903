import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useAuth } from '~/context/auth-context'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, isLoggedIn, isLoading } = useAuth()

  useEffect(() => {
    if (!isLoggedIn && !isLoading) {
      navigate('/account/login')
    }
  }, [isLoggedIn, isLoading])

  if (!isLoggedIn && !isLoading) {
    return null
  }

  if (rest.uri.indexOf('/ambassador') === 0 && user.role !== 'ambassador') {
    return (
      <>
        <div className="alert alert-danger">No access !!!</div>
      </>
    )
  }

  return <Component {...rest} />
}

export default PrivateRoute
